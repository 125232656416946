import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import * as FaIcons from "react-icons/fa6";
import aboutData from '../data/about.json'
import aboutlogo from '../media/va_logo.png';
import '../css/AboutPage.css';
import GoToTop from '../components/GoToTop'
import { HelmetAsync } from '../components/HelmetAsync';

const AboutPage = () => {

    const location = useLocation();
    const seoTitle = `About - VA Digimedia`;

    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);

return (
    <section className="page">
        <HelmetAsync
            key={location.pathname} // Force remount on route change
            title={seoTitle}
        />
        {aboutData.company.map((vad) => (
        <div className="container" key={vad.id}>
            <h1 className="mb-4">About {vad.name}</h1>
            <div className="row d-flex align-items-center">
                <div className="col-md-4 mb-4 mb-md-0 order-md-12">
                    <img
                    src={aboutlogo}
                    alt="About Us"
                    className="img-fluid rounded"
                    />
                </div>
                <div className="col-md-8">
                    {/* <h2 className="mb-4">Our Story</h2> */}
                    <p className='lead text-justify'>{vad.desc}</p>
                    {/* <h3 className="mt-4 mb-3">Our Values</h3>
                    <ul className="list-unstyled">
                        <li className="mb-2">
                            <FaIcons.FaCheck className="me-2 text-primary" />
                            Excellence in everything we do
                        </li>
                        <li className="mb-2">
                            <FaIcons.FaCheck className="me-2 text-primary" />
                            Customer satisfaction is our top priority
                        </li>
                        <li className="mb-2">
                            <FaIcons.FaCheck className="me-2 text-primary" />
                            Innovation and continuous improvement
                        </li>
                        <li className="mb-2">
                            <FaIcons.FaCheck className="me-2 text-primary" />
                            Integrity and transparency in all our dealings
                        </li>
                    </ul> */}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6 mb-4">
                    <div className="card h-100 shadow">
                    <div className="card-body">
                        <h3 className="card-title">Our Mission</h3>
                        <p className="card-text">{vad.mission}</p>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <div className="card h-100 shadow">
                    <div className="card-body">
                        <h3 className="card-title">Our Vision</h3>
                        <p className="card-text">{vad.vision}</p>
                    </div>
                    </div>
                </div>
                {/* <div className="col-md-6 mb-4">
                    <div className="card h-100 shadow">
                    <div className="card-body">
                        <h3 className="card-title">Our Team</h3>
                        <p className="card-text">A diverse group of passionate professionals dedicated to delivering exceptional results for our clients.</p>
                    </div>
                    </div>
                </div> */}
            </div>
        </div>
        ))}
        <GoToTop />
    </section>
  );
};

export default AboutPage;