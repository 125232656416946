import * as FaIcons from "react-icons/fa6";
import * as MdIcons from "react-icons/md";

export const getIconLibrary = (library) => {
    switch (library.toLowerCase()) {
        case 'fa':
        return FaIcons;
        case 'md':
        return MdIcons;
        default:
        return null;
    }
};

export const renderIcon = (iconName, library) => {
    const iconLibrary = getIconLibrary(library);
    
    if (!iconLibrary) {
        console.warn(`Icon library ${library} not found`);
        return null;
    }

    const IconComponent = iconLibrary[iconName];
    
    if (IconComponent) {
        return <IconComponent />;
    }
    
    console.warn(`Icon ${iconName} not found in ${library} library`);
    return null;
};

export const getIconComponent = (iconName, library) => {
    const iconLib = library === 'fa' ? FaIcons : MdIcons;
    return iconLib[iconName];
};
