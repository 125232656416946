import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import aboutData from '../../../data/about.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import * as FaIcons from "react-icons/fa6";
import * as MdIcons from "react-icons/md";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './testimonial.css';
import { renderIcon, getIconComponent } from '../../../utils/icons';

const Testimonials = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);

    // const catalog = catalogData.products.slice(0, 6);

    const handlePreviewClick = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideToLoop(index);
        }
    };

return (
    <div className="testimonial-section">
        <Swiper
            ref={swiperRef}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={20}
            // slidesPerView={2}
            breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                // when window width is >= 992px
                992: {
                  slidesPerView: 2,
                  spaceBetween: 30
                }
            }}
            autoplay={{
            delay: 3000,
            disableOnInteraction: true,
            }}
            loop={true}
            pagination={{
            clickable: true,
            el: '.swiper-pagination',
            // type: 'progressbar',
            }}
            navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            }}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            className="hero-swiper text-left"
        >
            {aboutData.testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
                {/* <div className="hero-background" style={{ backgroundImage: `url(${bgimage1})` }} ></div> */}
                <div className="hero-background" ></div>
                    <div className=" text-justify content">{testimonial.content}</div>
                    <div className="row mx-0 align-items-start">
                        <div className="col-9 px-0 test-cred">
                            {/* <h4>{testimonial.name}</h4> */}
                            <hr />
                            <p className='m-0'><span className='d-block'>{testimonial.name}</span>{testimonial.company}</p>
                        </div>
                        <div className="col-3 px-0 text-center">{renderIcon("MdOutlineFormatQuote","md")}</div>
                    </div>
                    {/* <Link to={`/products/${product.slug}`} className="product-info product-list">
                        {renderIcon(product.icon, product.library)}
                        <h2>{product.name}</h2>
                    </Link> */}
            </SwiperSlide>
            ))}

        </Swiper>
        <div className="swiper-pagination d-none"></div>
        <div className="swiper-button-prev">{renderIcon("FaAngleLeft","fa")}</div>
        <div className="swiper-button-next">{renderIcon("FaAngleRight","fa")}</div>
        {/* <div className="preview-container">
            {catalog.map((product, index) => (
            <div
                key={product.id}
                className={`preview-item ${index === activeIndex ? 'active' : ''}`}
                style={{ backgroundImage: `url(${bgimage1})` }}
                onClick={() => handlePreviewClick(index)}
            ></div>
            ))}
        </div> */}
    </div>
);
};

export default Testimonials;