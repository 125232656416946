import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
import * as MdIcons from "react-icons/md";
import catalogData from '../../../data/catalogData.json';
import itemimage from '../../../media/va_logo.png'
import '../../../css/products.css'
import { renderIcon } from '../../../utils/icons';

const ProductList = () => {
    const { productSlug } = useParams();
    const products = productSlug ? catalogData.products.filter(p => p.slug === productSlug) : catalogData.products;

    // const renderIcon = (iconName) => {
    //     // Get the icon component from FaIcons using the name from our data
    //     const IconComponent = FaIcons[iconName];
    //     // Check if the icon exists before rendering
    //     if (IconComponent) {
    //       return <IconComponent className="w-6 h-6 text-blue-600" />;
    //     }
    //     // Fallback if icon not found
    //     return null;
    // };


return (
    <div className="product-list">
        <ul>
            {products.map(product => (
                <li key={product.id}>
                    <div className="thumb-cont text-center">
                        {/* <div className="category thumb-cat">jfdl</div> */}
                        <Link to={`/products/${product.slug}`}>
                            {/* <div className="text-left thumbnail" style={{backgroundImage: "url(" + itemimage + ")"}}></div> */}
                            {renderIcon(product.icon, product.library)}
                            <h5 className="title">{product.name}</h5>
                        </Link>
                    </div>
                </li>
            ))}

            {/* <div className="">
                <div className="row">
                    <div className="col-8">
                        <h5 className="card-title">{product.name}</h5>
                    </div>
                    <div className="col text-right">
                        <Link to={`/products/${product.slug}`}>
                            <p className="card-title">View All</p>
                        </Link>
                    </div>
                </div>

                {product.subproducts ? (
                    <div className="list-group">
                    {product.subproducts.map(subproduct => (
                        <div key={subproduct.slug} className="list-group-item">
                            <Link className='subcat-link' to="#">
                                <img src={itemimage} alt="" />
                                {subproduct.name}
                            </Link>
                        </div>
                    ))}
                    </div>
                ) : (
                    // <Link to="#" className="subcat-link">
                    <Link to={`/products/${product.slug}`} className="subcat-link">
                        <img src={itemimage} alt="" />
                        <FaIcons.{product.icon} />
                        {product.icon}
                        {product.name}
                    </Link>
                )}
            </div> */}
        </ul>
    </div>
);
};

export default ProductList;